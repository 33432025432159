<template>
  <div>
    <el-carousel>
      <el-carousel-item v-for="item in qurey" :key="item">
        <el-image :src="item.imgUrl" alt class="banner-img" fit="contain" />
      </el-carousel-item>
    </el-carousel>
    <div v-for="item in data" :key="item.id">
      <div v-if="item.type !== 1" class="tit-i">
        <img src="../assets/Group.png" alt>
        <h3 v-if="item.type === 1" style="color:#ffff;padding:0px 10px">{{ item.title }}</h3>
        <h3 v-else style="color:blake">{{ item.title }}</h3>
        <img src="../assets/Group Copy.png" alt>
        <h5 v-if="item.type===1" style="color:#ffff">
          {{ item.subTitle }}
          <!-- <span>SERVICE</span> -->
        </h5>
        <h5 v-else style="color:blake">
          {{ item.subTitle }}
          <!-- <span>SERVICE</span> -->
        </h5>
      </div>
      <!-- 云服务 -->
      <div v-if="item.type === 1">
        <div class="bjtp">
          <h3 style="color:#ffff;padding:0px 10px;margin-top:auto">{{ item.title }}</h3>
          <h5 style="color:#ffff;margin-bottom:auto">
            {{ item.subTitle }}
            <!-- <span>SERVICE</span> -->
          </h5>
        </div>
      </div>
      <!-- 和仲动态 -->

      <template v-else-if="item.type === 4">
        <div
          v-for="subitem in item.subitemEntities"
          :key="subitem.id"
          style="display:flex;margin:10px 0;"
        >
          <div class="dynamic">
            <div class="imgleft">
              <el-image :src="subitem.imgUrl" alt :lazy="true" /></div>
            <div class="details">
              <div class="dynfont">
                <span class="min">{{ subitem.title }}</span>
                <br>
                <span class="minabout">{{ subitem.subTitle }}</span>
              </div>
              <div class="dynfonts ql-editor" v-html="subitem.detail">
                <!-- {{ subitem.detail }} -->
                <!-- 凭借专业的大中型客户经营能力、强大的产品研发能力和一体化作战的服务能力，一直被业界誉为专业型、 创新型和引领型的智慧办公平台领航者。作为核心参编单位，发起并推动《知识管理标准》的颁发； 作为唯一授权单位，推动“全球MAKE”大奖在国内的评选， 旨在将实战性、实用性和前瞻性的应用实践带给更多的客户。 未来，将继续与所有的客户和伙伴一道，共同推动企业工作方式的创新与变革， 让每个企业都拥有更智慧的工作方式。
                凭借专业的大中型客户经营能力、强大的产品研发能力和一体化作战的服务能力，一直被业界誉为专业型、 创新型和引领型的智慧办公平台领航者。作为核心参编单位，发起并推动《知识管理标准》的颁发； 作为唯一授权单位，推动“全球MAKE”大奖在国内的评选， 旨在将实战性、实用性和前瞻性的应用实践带给更多的客户。 未来，将继续与所有的客户和伙伴一道，共同推动企业工作方式的创新与变革， 让每个企业都拥有更智慧的工作方式。
                凭借专业的大中型客户经营能力、强大的产品研发能力和一体化作战的服务能力，一直被业界誉为专业型、 创新型和引领型的智慧办公平台领航者。作为核心参编单位，发起并推动《知识管理标准》的颁发； 作为唯一授权单位，推动“全球MAKE”大奖在国内的评选， 旨在将实战性、实用性和前瞻性的应用实践带给更多的客户。 未来，将继续与所有的客户和伙伴一道，共同推动企业工作方式的创新与变革， 让每个企业都拥有更智慧的工作方式。
                凭借专业的大中型客户经营能力、强大的产品研发能力和一体化作战的服务能力，一直被业界誉为专业型、 创新型和引领型的智慧办公平台领航者。作为核心参编单位，发起并推动《知识管理标准》的颁发； 作为唯一授权单位，推动“全球MAKE”大奖在国内的评选， 旨在将实战性、实用性和前瞻性的应用实践带给更多的客户。 未来，将继续与所有的客户和伙伴一道，共同推动企业工作方式的创新与变革， 让每个企业都拥有更智慧的工作方式。-->
              </div>
            </div>
          </div>
        </div>
      </template>
      <div
        v-else
        style="display:flex;
    margin: 0px auto;"
      >
        <!-- 联系我们 -->
        <!-- <div v-if="item.type === 5" class="contact">
          <div class="contactleft">
            <div>
              <img src="../assets/weizhi.png" alt></div>
            <div>
              <span style="white-space: pre-line">{{ item.detail }}</span>
            </div>
          </div>
          <div class="contactright">
            <el-form ref="form" :rules="rules" :model="form" class="demo-form-inline" :inline="true">
              <el-form-item>
                <el-input v-model="form.name" placeholder="你的姓名" style="margin:0" />
              </el-form-item>
              <el-form-item>
                <el-input v-model="form.contact" placeholder="你的联系方式" style="margin:0" />
              </el-form-item>
              <br>
              <el-form-item>
                <el-input
                  v-model="form.others"
                  type="textarea"
                  style="width:385px"
                  :autosize="{ minRows: 4, maxRows: 6}"
                />
              </el-form-item>
            </el-form>
            <el-button type="primary" class="but" @click="handelsubmit()">提交你的需求</el-button>
          </div>
        </div> -->
        <!-- 和仲案例 -->
        <div
          v-if="item.type === 3"
          class="case"
          style="display: flex;
                  margin: 0 auto;
                  width: 1246px;
                  flex-wrap: wrap;"
        >
          <el-image-viewer
            v-if="showViewer"
            :on-close="closeViewer"
            :url-list="srcList"
          />
          <div v-for="subitem in item.subitemEntities" :key="subitem.id" class="bg">
            <el-image :src="subitem.imgUrl" class="case-bg-img" :lazy="true" />
            <div class="bgcolo" @click="handleShowPreview(subitem.imagesEntities)">
              <div class="font">
                <span class="oa">{{ subitem.title }}</span>
                <br>
                <span class="mode">
                  {{ subitem.subTitle }}
                </span>
              </div>
            </div>
            <img src="../assets/bf.png" alt class="case-play-btn">
          </div>
        </div>

        <!-- 和仲服务 -->
        <div v-else style="display:flex;width:1216px;margin:0 auto;flex-wrap: wrap;">
          <template v-for="subitem in item.subitemEntities">
            <!-- :src="subitem.imagesEntities.length > 0 ? subitem.imagesEntities[0].imgUrl : ''" -->
            <router-link :key="subitem.id" :to="'/servicedetails?id='+ subitem.id">
              <div>
                <div class="service" style="text-align:center;margin:10px 0;">
                  <router-link :key="subitem.id" :to="'/servicedetails?id='+ subitem.id">
                    <el-image
                      :src="subitem.imgUrl"
                      fit="cover"
                      :lazy="true"
                      alt
                      style="width:270px;height:340px;border-radius:10px;margin:0 17px;box-shadow:0 2px 12px 0 rgb(0 0 0 / 20%)"
                    />
                  </router-link>
                  <h3>{{ subitem.title }}</h3>
                </div>
              </div>
            </router-link>
          </template>
        </div>
      </div>
      <router-link to="/hezhongservice">
        <div v-if="item.type ===2 " class="moreServices">查看更多</div>
      </router-link>
      <router-link to="/seemore">
        <div v-if="item.type ===3 " class="moreServices">查看更多</div>
      </router-link>
      <router-link to="/hezhongdynamic">
        <div v-if="item.type ===4 " class="moreServices" style="margin-bottom: 40px;">查看更多</div>
      </router-link>
    </div>
    <!--  -->
  </div>
</template>
<script>
import { aa, getSubmit, getBanner } from '../api/pc/home'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

export default {
  components: { ElImageViewer },
  data() {
    return {
      data: {},
      qurey: [],
      list: [
        {
          name: 'ncsa',
          id: 1
        },
        {
          name: 'ncsa',
          id: 2
        }
      ],
      form: {
        name: '',
        contact: '',
        others: ''
      },
      rules: {
        name: [
          // { required: true, message: '请输入名称', trigger: 'blur' },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
          { required: true, trigger: 'blur', message: '用户名不能为空' }
        ],
        contact: [
          { required: true, trigger: 'blur', message: '手机号码不能为空' }
        ],
        others: [
          { required: true, trigger: 'blur', message: '备注不能为空' }
        ]

      },
      srcList: [],
      showViewer: false
    }
  },
  created() {
    this.handelBnaner()
    aa()
      .then(res => {
        console.log('res', res)
        // res.data.item.parentDOS[1].subitemEntities.push(res.data.item.parentDOS[1].subitemEntities[0])
        this.data = res.data.item
      })
      .catch(e => {
        console.error(e)
      })
    // for (let i = 0; i < this.list.length; i++) {
    //   const item = this.list[i];
    //   console.log(item);
    // }
  },
  methods: {
    // 提交
    handelsubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          getSubmit(this.form).then(res => {
            console.log('getSubmit', res)
            this.$message({
              message: '提交成功！感谢您的建议',
              type: 'success'
            })
            this.handleClose()
          }).catch(e => {
            this.$message({
              message: '提交失败',
              type: 'danger'
            })
            this.subLoading = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 清空
    handleClose() {
      this.dialogFormVisible = false
      this.form = {}
    },
    handleShowPreview(imgs) {
      console.log('handleShowPreview')
      if (!imgs || imgs.length === 0) {
        return
      }
      this.srcList = imgs.map(o => o.imgUrl)
      console.log('srcList', this.srcList)
      this.showViewer = true
    },
    closeViewer(e) {
      this.showViewer = false
    },
    handelBnaner() {
      getBanner()
        .then(res => {
          console.log('banner', res)
          this.qurey = res.data.item
        })
        .catch(e => {
          console.error(e)
        })
    }
  }

}
</script>
<style  lang="scss" scoped>
/**轮播图 */
.el-carousel {
  width: 100%;

  ::v-deep .el-carousel__container {
    height: 500px;
    .el-carousel-item {
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .banner-img {
    width: 100%;
  }
}

/** 和仲服务 */
.wp {
  a {
    display: block;
    span {
      display: block;
    }
  }
  .ul-icon-i2 {
    .li2 {
      float: left;
      overflow: hidden;
      text-align: center;
      margin-top: -29px;
    }
  }
}
.fix {
  *zoom: 1;
  &:after,
  &:before {
    display: block;
    content: "clear";
    height: 0;
    clear: both;
    overflow: hidden;
    visibility: hidden;
  }
}
.tit-i {
  padding: 20px 0;
  text-align: center;
  padding: 50px 0;
  line-height: 36px;
  overflow: hidden;
  h3 {
    font-size: 30px;
    color: #002;
    margin-bottom: 0px;
    display: inline-block;
  }
  h5 {
    font-size: 20px;
    color: #333333;
    text-transform: uppercase;
    font-family: Arial;
    margin-top: 10px;
    span {
      color: #e4392a;
    }
  }
  em {
    width: 45px;
    border-bottom: 1px solid #7f7f90;
    display: inline-block;
  }
}

/** */
.ul-icon-i2 {
  .li2 {
    .pad2 {
      width: 200px;
      a {
        .ardios {
          width: 113px;
          height: 113px;
          background-color: red;
          /* padding-left: 26px; */
          margin-left: 42px;
          margin-bottom: 22px;
          border-radius: 60px;
        }
        .font {
        }
      }
    }
  }
}
.contact {
  padding-bottom: 164px;
  display: flex;
  flex: 1;
  margin: 0 155px;
  .contactleft {
    img {
      width: 34px;
    }
    margin-left: auto;
    display: flex;
  }
  .contactright {
    .but {
      width: 387px;
    }
    margin-left: 100px;
    margin-right: auto;
  }
}
.bg {
  // background-image: url(../assets/rectangle2.png);
  position: relative;
  background-size: 100% 100%;
  display: inline-block;
  width: 380px;
  height: 282px;
  margin-left: 23px;
  margin-bottom: 22px;
  .case-bg-img {
    width: 380px;
    height: 282px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 23px;
    box-shadow: 0px 2px 8px 0px rgba(#409eff, 0.6);
  }
  .case-play-btn {
    width: 38px;
    position: absolute;
    right: 10px;
    bottom: 10px;
    padding-top: 43px;
    cursor: pointer;
  }
  .bgcolo {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.3);
    width: 377px;
    height: 282px;
    border-radius: 23px;
    text-align: center;
    position: relative;
    .font {
      padding-top: 100px;
      .oa {
        width: 260px;
        height: 42px;
        font-size: 30px;
        font-family: Helvetica;
        color: #ffffff;
        line-height: 36px;
      }
      .mode {
        width: 180px;
        height: 46px;
        font-size: 16px;
        font-family: Helvetica;
        color: #ffffff;
        line-height: 23px;
      }
    }
  }
}
.bjtp {
  background-image: url(../assets/banner2.png);
  height: 284px;
  margin: 20px 0;
  // margin-top: -230px;
  text-align: center;
  display: flex;
  flex-direction: column;
  h3 {
    font-size: 30px;
    color: #002;
    margin-bottom: 0px;
    display: inline-block;
  }
  h5 {
    font-size: 20px;
    color: #333333;
    text-transform: uppercase;
    font-family: Arial;
    margin-top: 10px;
    span {
      color: #e4392a;
    }
  }
  em {
    width: 45px;
    border-bottom: 1px solid #7f7f90;
    display: inline-block;
  }
}
.dynamic {
  position: relative;
  margin: 0 auto;
  width: 1127px;
  .dynfont {
    margin-left: 24px;
    margin-top: 78px;
    .min {
      width: 133px;
      height: 37px;
      font-size: 26px;
      font-family: Helvetica;
      color: #4a4a4a;
      line-height: 31px;
    }
    .minabout {
      width: 133px;
      height: 17px;
      font-size: 14px;
      font-family: Helvetica;
      color: #bbbbbb;
      line-height: 17px;
    }
  }
  .dynfonts {
    width: 671px;
    height: 320px;
    font-size: 14px;
    font-family: Helvetica;
    color: #4a4a4a;
    line-height: 26px;
    margin-top: 30px;
    margin-left: 24px;
    overflow-y: auto;
    ::v-deep {
      img{
      max-width: 100%;
    }
    }
  }
  .imgleft {
    display: inline-block;
    img {
      width: 542px;
      height: 596px;
      border-radius: 20px;
      box-shadow: 0px 2px 8px 0px rgba(#409eff, 0.6);
    }
  }
  .details {
    position: absolute;
    display: inline-block;
    width: 727px;
    height: 489px;
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 26%);
    top: 58px;
    left: 412px;
    border-radius: 10px;
  }
}
.moreServices {
  width: 200px;
  height: 50px;
  border-radius: 29px;
  border: 1px solid #2671e9;
  font-size: 16px;
  font-family: Helvetica;
  color: #2671e9;
  line-height: 47px;
  margin: 0 auto;
  text-align: center;
  margin-top: 45px;
  cursor: pointer;
}
.moreServices:hover {
  color: #ffffff;
  background-color: #2671e9;
}
.case {
  margin: 0 auto;
}
</style>

